<template>
  <div>
    <template slot="header">
      <div class="row">
        <div class="col-6">
          <h3 class="mb-0">{{ $t("SUPPLIERS.SUPPLIERS_LIST") }}</h3>
        </div>
        <div class="col-6 text-right">
          <base-button type="primary" icon size="sm" @click="addSupplier()"
            v-if="$currentUserCan($permissions.PERM_CREATE_SUPPLIERS)">
            <span class="btn-inner--icon">
              <i class="fas fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{
              $t("SUPPLIERS.ADD_SUPPLIER")
            }}</span>
          </base-button>
        </div>
      </div>
    </template>

    <div>
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <el-select class="select-primary pagination-select" v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')">
          <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
            :value="item">
          </el-option>
        </el-select>

        <organization-selector v-if="
          !filterOrganization &&
          $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        " @organizationChanged="(organizationId) => (selectedOrganization = organizationId)
          " />

        <base-input v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          :placeholder="`${$t('COMMON.LOCATIONS')}`">
          <locations-selector @locationsChanged="(locations) => (selectedLocations = locations)" />
        </base-input>

        <base-input :placeholder="`${$t('COMMON.TAGS')}`">
          <tags-selector @tagsChanged="(tags) => {
            selectedTags = tags;
          }
            " :organization="selectedOrganization" />
        </base-input>

        <el-select class="select-primary pagination-select" v-model="selectedActive"
          :placeholder="$t('COMMON.PER_PAGE')">
          <el-option class="select-primary" :label="`${$t('COMMON.ALL')} `"
            :value="null">
          </el-option>

          <el-option class="select-primary" :label="`${$t('SUPPLIERS.ACTIVE')} `"
            :value="true">
          </el-option>

          <el-option class="select-primary" :label="`${$t('SUPPLIERS.SUPPLIER_DEACTIVATED')} `"
            :value="false">
          </el-option>
        </el-select>


        <div>
          <base-input v-model="query" type="search" prepend-icon="fas fa-search" :placeholder="$t('COMMON.CODE')"
            clearable />
        </div>
      </div>
      <el-table class="align-items-center table-flush" header-row-class-name="thead-light"
        :data="loading ? [] : suppliers" @sort-change="sortChange">
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>

        <el-table-column :label="$t('COMMON.COMPANY_NAME')" sortable="custom" prop="company_name" min-width="250px" />

        <el-table-column :label="$t('COMMON.PHONE')" prop="phone" sortable="custom" min-width="250px" />

        <el-table-column :label="$t('COMMON.FISCAL_NUMBER')" sortable="custom" prop="fiscal_number" min-width="250px" />

        <el-table-column :label="$t('COMMON.EMAIL')" prop="email" sortable="custom" min-width="250px" />

        <el-table-column :label="$t('COMMON.TAGS')" prop="tags" min-width="250px">
          <template v-slot="{ row }">
            <tags :tags="row.tags" @tagsUpdated="getList" />
          </template>
        </el-table-column>
        <el-table-column :label="$t('COMMON.LOCATIONS')" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          min-width="250px">
          <template v-slot="{ row }">
            <locations :locations="row.allowedLocations" />
          </template>
        </el-table-column>
        <el-table-column :label="$t('COMMON.ORGANIZATION')" v-if="
          !filterOrganization &&
          $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        " min-width="250px">
          <template v-slot="{ row }">
            <organization :organization="row.organization" />
          </template>
        </el-table-column>

        <el-table-column :label="$t('COMMON.CREATED_AT')" prop="created_at" sortable="custom" min-width="200px">
          <template v-slot="{ row }">
            {{ $timeZoneDateFormat(row.created_at) }}
          </template>
        </el-table-column>

        <el-table-column min-width="100px" align="center" fixed="right">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip :content="$t('COMMON.VIEW')" placement="top"
              v-if="$currentUserCan($permissions.PERM_VIEW_SUPPLIERS)">
              <a type="text" @click="viewSupplier(row)" class="table-action" data-toggle="tooltip"
                style="cursor: pointer">
                <i class="fas fa-eye"></i>
              </a>
            </el-tooltip>

            <el-tooltip :content="$t('COMMON.EDIT')" placement="top"
              v-if="$currentUserCan($permissions.PERM_EDIT_SUPPLIERS)">
              <a type="text" @click="editSupplier(row)" class="table-action" data-toggle="tooltip"
                style="cursor: pointer">
                <i class="fas fa-edit"></i>
              </a>
            </el-tooltip>

            <el-tooltip content="Delete" placement="top" v-if="$currentUserCan($permissions.PERM_DELETE_SUPPLIERS)">
              <a type="text" @click="deleteSupplier(row.id)" class="table-action table-action-delete"
                data-toggle="tooltip" style="cursor: pointer">
                <i class="fas fa-trash"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>

    <div slot="footer" class="mt-5 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
      <div class="">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
      </div>
      <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
        :total="total" />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import Tags from "@/components/Tags.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";

export default {
  name: "supplier-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    Tags,
    TagsSelector,
    LocationsSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterSupplierRuleSelected: {
      type: Array,
      default: null,
      description: "Supplier rule selected",
    },
    filterByProduct: {
      type: String,
      default: null,
      description: "Product id",
    },
  },

  data() {
    return {
    
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      suppliers: [],
      loading: true,
      selectedOrganization: null,
      selectedLocations: null,
      selectedTags: [],
      selectedActive: true,

    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterByProduct: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedTags: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedActive: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterSupplierRuleSelected: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      this.loading = true;
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,tags,allowedLocations",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }

        if (this.filterByProduct) {
          params = {
            ...params,
            filter: { ...params.filter, byProduct: this.filterByProduct },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.filterSupplierRuleSelected) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              supplyRules: this.filterSupplierRuleSelected.map(
                (item) => item.id
              ),
            },
          };
        }
        if (this.selectedTags) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              tags: this.selectedTags.map((item) => item.id),
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }
        if (this.selectedActive != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              active: this.selectedActive ? 1 :0,
            },
          };
        }
        await this.$store.dispatch("suppliers/list", params);
        this.suppliers = this.$store.getters["suppliers/list"];
        this.total = this.$store.getters["suppliers/listTotal"];
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteSupplier(id) {
      const confirmation = await swal.fire({
        title: this.$t("SUPPLIERS.DELETE_THIS_SUPPLIER"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("suppliers/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("SUPPLIERS.SUPPLIER_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewSupplier(supplier) {
      this.$router.push({
        name: "View Supplier",
        params: { id: supplier.id },
      });
    },

    async editSupplier(row) {
      await this.$router.push({
        name: "Edit Supplier",
        params: { id: row.id },
      });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
